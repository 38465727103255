const TheHomeMain = () => import('../views/TheHomeMain')
const TheHomeDashboard = () => import('../views/TheHomeDashboard')
const TheHomeAddEducationSpace = () => import('../views/TheHomeAddEducationSpace')
const TheHomeAddResearchSpace = () => import('../views/TheHomeAddResearchSpace')
const TheHomeAddDatasetSpace = () => import('../views/TheHomeAddDatasetSpace')
const TheHomeOrganizationUserManagement = () => import('../views/TheHomeOrganizationUserManagement')
const TheHomeOrganizationConfig = () => import('../views/TheHomeOrganizationConfig')
const TheHomeOrganizationTokens = () => import('@/modules/organization/views/TheOrgTokens')
const TheHomeEducationSpaceList = () => import('../views/TheHomeEducationSpaceList')
const TheHomeResearchSpaceList = () => import('../views/TheHomeResearchSpaceList')
const TheHomeDatasetSpaceList = () => import('../views/TheHomeDatasetSpaceList')
const Steppers = () => import('@/components/Steppers')

export default [
    {
        path: '/org/:oid',
        name: 'home-main',
        components: {
            default: TheHomeMain
        },
        children: [
            {
                path: 'dashboard',
                name: 'home-dashboard',
                components: {
                    default: TheHomeDashboard
                }
            },
            {
                path: 'education-spaces',
                name: 'home-education-spaces',
                components: {
                    default: TheHomeEducationSpaceList
                }
            },
            {
                path: 'research-spaces',
                name: 'home-research-spaces',
                components: {
                    default: TheHomeResearchSpaceList
                }
            },
            {
                path: 'dataset-spaces',
                name: 'home-dataset-spaces',
                components: {
                    default: TheHomeDatasetSpaceList
                }
            },
            {
                path: 'config',
                name: 'home-org-config',
                components: {
                    default: TheHomeOrganizationConfig
                }
            },
            {
                path: 'users',
                name: 'home-org-users',
                components: {
                    default: TheHomeOrganizationUserManagement
                }
            },
            {
                path: 'tokens',
                name: 'home-org-tokens',
                components: {
                    default: TheHomeOrganizationTokens
                }
            },
            {
                path: 'education-spaces/add-class',
                name: 'home-add-class',
                components: {
                    default: TheHomeAddEducationSpace,
                    notifications: Steppers
                }
            },
            {
                path: 'research-spaces/add-research-project',
                name: 'home-add-research-project',
                components: {
                    default: TheHomeAddResearchSpace,
                    notifications: Steppers
                }
            },
            {
                path: 'dataset-spaces/add-dataset',
                name: 'home-add-dataset',
                components: {
                    default: TheHomeAddDatasetSpace,
                    notifications: Steppers
                }
            }
        ]
    }
]
