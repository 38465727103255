import axios from 'axios'
import { getInstance } from './auth/auth'
import LogRocket from 'logrocket'
import axiosRetry from 'axios-retry'
import store from './store'
import regionsMixin from './mixins/regions'

let baseURL = ''
if (window.appConfig?.VUE_APP_ENV === 'production') {
    baseURL = window.appConfig?.VUE_APP_BACKEND_URL
} else {
    baseURL = localStorage.getItem('nvBaseUrl')
    if (!baseURL) {
        baseURL = window.appConfig?.VUE_APP_BACKEND_URL
    }
}

const $axios = axios.create({
    baseURL,
    timeout: 300000,
    headers: { 'Content-Type': 'application/json' }
})

axiosRetry($axios, {
    retries: 2,
    retryDelay: retryCount => {
        return retryCount * 1000
    }
})

// Request Interceptor
$axios.interceptors.request.use(
    async config => {
        if (getInstance().isAuthenticated) {
            const accessToken = await getInstance().getTokenSilently()
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        config.metadata = { startTime: new Date() }
        return config
    },
    error => {
        Promise.reject(error)
    }
)

$axios.interceptors.response.use(
    function (response) {
        const metadata = response.config.metadata
        metadata.endTime = new Date()
        response.duration = metadata.endTime - metadata.startTime
        if (response.duration > response.headers['x-runtime-ms'] * 10) {
            const info = {
                endpoint: response.request.responseURL,
                method: response.config.method,
                status: response.status,
                feStart: metadata.startTime,
                feRuntime: response.duration,
                beRuntime: response.headers['x-runtime-ms']
            }
            console.warn(`${response.request.responseURL.split('/').pop()}: `, info)
            LogRocket.captureException(new Error('Long running request.'), {
                extra: info
            })
        }
        return response
    },
    function (error) {
        if (error.response) {
            const { status } = error.response
            if (error.response.data && error.response.data.code === 'user_disabled') {
                store.dispatch('hideSnackBar')
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Deactivated account',
                    dialogText: 'You account has been deactivated. You can request reactivation of your account at support@nuvolos.cloud.',
                    dialogAction: ['logout']
                })
            }
            if (status === 401 || (error.response.data && error.response.data.code === 'token_expired')) {
                if (!(error.response.data && error.response.data.code === 'vpn_credentials_missing')) {
                    store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Your session has ended!',
                        dialogText: 'Your session has expired, please reload the page.',
                        dialogAction: ['reload']
                    })
                }
            }
            if (error.response.data && error.response.data.code === 'instance_missing') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Unavailable data',
                    dialogText: 'This instance does not exist or it has been deleted.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'quick_detailed_snapshot_limit_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Snapshot limit reached',
                    dialogText:
                        'You have reached the maximum number of on-demand snapshots. Please delete snapshots that are no longer needed. Auto snapshots do not count against the limit.',
                    dialogAction: ['dismiss']
                })
            }
            if (error.response.data && error.response.data.code === 'already_accepting') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Accepting invitation',
                    dialogText: 'This invitation has already been accepted, please continue to wait a bit and reload the page after 1-2 minutes.',
                    dialogAction: ['reload']
                })
            }
            if (error.response.data && error.response.data.code === 'already_registering') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Registering account',
                    dialogText:
                        'Registration for this invitation has already been started, please continue to wait a bit and reload the page after 1-2 minutes.',
                    dialogAction: ['reload']
                })
            }
            if (error.response.data && (error.response.data.code === 'invitation_has_expired' || error.response.data.code === 'invitation_revoked')) {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Invitation not valid!',
                    dialogText: 'This invitation has expired or has been revoked.',
                    dialogAction: ['dismiss']
                })
            }
            if (error.response.data && error.response.data.code === 'unidentifiable_instance_request') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Unavailable data',
                    dialogText: 'This instance does not exist or it has been deleted.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'space_missing') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Unavailable data',
                    dialogText: 'This space does not exist or it has been deleted.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'org_missing') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Unavailable data',
                    dialogText: 'This organization does not exist or it has been deleted.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'email_already_registered_error') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Existing account',
                    dialogText: 'An account already exists with this email address. Please sign in with the existing account to accept your invitation.',
                    dialogAction: ['dismiss']
                })
            }
            if (error.response.data && error.response.data.code === 'concurrency_limit_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Application concurrency limit reached',
                    dialogText: 'Contact one of your lecturers/TAs that the concurrency limit has been exhausted in the course.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'application_deleted') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Application deleted / moved',
                    dialogText: 'This application was deleted (or an older version restored), if accessing from a bookmark, please update it.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'insufficient_org_role') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Permission denied',
                    dialogText: 'You do not have permission for this action, please check if you have the right URL.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'insufficient_instance_role') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Permission denied',
                    dialogText: 'You do not have permission for this action, please check if you have the right URL.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'application_missing') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Application not found',
                    dialogText: 'This application does not seem to exist, please check the URL.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && /space.*_in_.*/.test(error.response.data.code)) {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Space in different region',
                    dialogText: 'This space is in a different region, redirecting to it shortly. You might be required to sign in again.',
                    dialogAction: ['dismiss']
                })
                const regionCodeFromErrorMessage = error.response.data.code.replace(/space.*_in_(.*)/, '$1')
                setTimeout(() => {
                    document.location = regionsMixin.methods.changeRegionURL(regionCodeFromErrorMessage)
                }, 5000)
            }
            if (error.response.data && /space_.*_migrating/.test(error.response.data.code)) {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Space is migrating',
                    dialogText:
                        'This space is in the process of being migrated to a different region and is currently not accessible. Please check back a bit later.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'max_members_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Max members reached',
                    dialogText: 'Trial has already reached the max user count, signup is not allowed.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'restoring_snapshot') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Snapshot being restored',
                    dialogText: 'A snapshot restore operation is in progress, please wait for it to finish, before continuing.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'max_spaces_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Max spaces reached',
                    dialogText: 'Trial has already reached max space count, new space creation is not allowed.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'trial_ended') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Trial ended',
                    dialogText: 'The trial end date has passed.',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'instance_archived') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Application start failed',
                    dialogText: 'The instance of this application is in an archived state, please restore a snapshot first to use applications.',
                    dialogAction: ['toInstanceSnapshots']
                })
            }
            if (error.response.data && error.response.data.code === 'max_quota_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Application start failed',
                    dialogText:
                        'You are very near to exhaust your file storage quota, therefore the application could not be started. Please remove some files to free up space or request a quota increase via Intercom.',
                    dialogAction: ['toSnapshotFiles']
                })
            }
            if (error.response.data && error.response.data.code === 'user_concurrency_limit_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Maximum number of applications running reached!',
                    dialogText:
                        'You have reached the maximal number of concurrent applications allowed (5). Please stop running applications before starting new ones.',
                    dialogAction: ['returnToWelcomePage']
                })
            }

            if (error.response.data && error.response.data.code === 'vimeo_not_enabled_org') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Video library not enabled!',
                    dialogText: 'Video library upload is not enabled for your organization, please ask us to enable it for you.',
                    dialogAction: ['dismiss']
                })
            }

            if (error.response.data && error.response.data.code === 'vimeo_not_enabled_space') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Video library not enabled!',
                    dialogText: 'Video library upload is not enabled in your space. Please go to space configuration and enable video library upload.',
                    dialogAction: ['dismiss']
                })
            }

            if (error.response.data && error.response.data.code === 'startup_too_soon') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Wrong scheduled startup time!',
                    dialogText: 'Creating scheduled startup is not allowed because the selected time is in less than half an hour.',
                    dialogAction: ['close']
                })
            }

            if (error.response.data && error.response.data.code === 'maximum_allowed_schedules_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: "Can't add new schedule!",
                    dialogText: 'Your space has reached the maximum number of allowed scheduled startups (20).',
                    dialogAction: ['close']
                })
            }

            if (error.response.data && error.response.data.code === 'app_limit_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: "Can't add new app!",
                    dialogText: error.response.data.description,
                    dialogAction: ['close']
                })
            }
            if (error.response.data && error.response.data.code === 'instance_limit_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: "Can't create new instance!",
                    dialogText: error.response.data.description,
                    dialogAction: ['close']
                })
            }
            if (error.response.data && error.response.data.code === 'ssh_token_limit_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: "Can't add new ssh token!",
                    dialogText: error.response.data.description,
                    dialogAction: ['close']
                })
            }
            if (error.response.data && error.response.data.code === 'space_limit_reached') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: "Can't create new space!",
                    dialogText: error.response.data.description,
                    dialogAction: ['close']
                })
            }
            if (error.response.data && error.response.data.code === 'snapshot_rate_limit_error') {
                store.dispatch('showGlobalDialog', {
                    dialogTitle: "Can't create new snapshot!",
                    dialogText: 'A snapshot is already being created, please wait for it to finish. You can check your task manager for running tasks.',
                    dialogAction: ['close']
                })
            }
            if (error.response.data && error.response.data.code === 'app_already_started') {
                store.dispatch('showGlobalDialog', {
                    dialogText: 'App already started.',
                    dialogTitle: 'Application start failed',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'space_doesnt_have_tables') {
                store.dispatch('showGlobalDialog', {
                    dialogText: 'Tables feature must be enabled in the space before this application can be used.',
                    dialogTitle: 'Application start failed',
                    dialogAction: ['returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'no_ncu_hours_for_contract') {
                store.dispatch('showGlobalDialog', {
                    dialogText: 'The resource pool this application belongs to, does not have enough NCU hours to start the app.',
                    dialogTitle: 'Application start failed',
                    dialogAction: ['contactSupport', 'returnToWelcomePage']
                })
            }
            if (error.response.data && error.response.data.code === 'user_trial_expired') {
                store.dispatch('showGlobalDialog', {
                    dialogText: 'Your trial is up, cannot start applications anymore.',
                    dialogTitle: 'Trial expired',
                    dialogAction: ['contactSupport', 'returnToWelcomePage']
                })
            }
        }
        return Promise.reject(error)
    }
)

export default $axios
