const TheUserMain = () => import('../views/TheUserMain')
const TheUserTasks = () => import('../views/TheUserTasks.vue')
const TheUserRequests = () => import('../views/TheUserRequests.vue')
const TheUserSettings = () => import('../views/TheUserSettings.vue')
const TheUserResourcesAndUsage = () => import('../views/TheUserResourcesAndUsage.vue')
const MonitoringDashboard = () => import('../views/MonitoringDashboard.vue')
const TheUserProfile = () => import('../views/TheUserProfile')
const TheUserSSHKeys = () => import('../views/TheUserSSHKeys')
const TheUserSecrets = () => import('../views/TheUserSecrets')
const TheUserEnvVars = () => import('../views/TheUserEnvVars')
const TheUserSnowflakeTokens = () => import('../views/TheUserSnowflakeTokens')
const TheUserDropboxLink = () => import('../views/TheUserDropboxLink')
const TheUserApiAccess = () => import('../views/TheUserApiAccess')

export default [
    {
        path: '/user',
        name: 'user-main',
        components: {
            default: TheUserMain
        },
        children: [
            {
                path: 'user-tasks',
                name: 'user-tasks',
                components: {
                    default: TheUserTasks
                }
            },
            {
                path: 'user-requests',
                name: 'user-requests',
                components: {
                    default: TheUserRequests
                }
            },
            {
                path: 'resources',
                name: 'user-resources',
                components: {
                    default: TheUserResourcesAndUsage
                }
            },
            {
                path: 'reporting-dashboard',
                name: 'reporting-dashboard',
                components: {
                    default: MonitoringDashboard
                }
            },
            {
                path: 'settings',
                name: 'user-settings',
                components: {
                    default: TheUserSettings
                },
                children: [
                    {
                        path: 'profile',
                        name: 'user-profile',
                        components: {
                            default: TheUserProfile
                        }
                    },
                    {
                        path: 'ssh',
                        name: 'user-ssh',
                        components: {
                            default: TheUserSSHKeys
                        }
                    },
                    {
                        path: 'secrets',
                        name: 'user-secrets',
                        components: {
                            default: TheUserSecrets
                        }
                    },
                    {
                        path: 'env-vars',
                        name: 'user-env-vars',
                        components: {
                            default: TheUserEnvVars
                        }
                    },
                    {
                        path: 'tables',
                        name: 'user-tables',
                        components: {
                            default: TheUserSnowflakeTokens
                        }
                    },
                    {
                        path: 'dropbox',
                        name: 'user-dropbox',
                        components: {
                            default: TheUserDropboxLink
                        }
                    },
                    {
                        path: 'api-access',
                        name: 'user-api-access',
                        components: {
                            default: TheUserApiAccess
                        }
                    }
                ]
            }
        ]
    }
]
