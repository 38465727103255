import $axios from '@/backend.js'
import router from '@/router'
import { sortArray, isManagerRole, isFacultyRole, isAdminRole } from '@/utils'
const orgStore = {
    namespaced: true,
    state: {
        orgUsers: [],
        orgTokens: [],
        orgInvitations: [],
        spacesWithPrimarySnapshots: [],
        fetchingSpaces: false,
        fetchingOrgUsers: false,
        fetchingOrgTokens: false,
        fetchingOrgInvitations: false,
        fetchingInvitations: false,
        educationSpaces: [],
        researchSpaces: [],
        datasetSpaces: [],
        recentEducationSpaces: [],
        recentResearchSpaces: [],
        recentDatasetSpaces: []
    },
    mutations: {
        setUsers: function (state, users) {
            state.orgUsers = users
        },
        setOrgInvitations: function (state, invitations) {
            state.orgInvitations = invitations
        },
        setTokens: function (state, tokens) {
            state.orgTokens = tokens
        },
        setFetching: function (state, { fetchingObject, fetchingStatus }) {
            if (fetchingObject === 'orgSpaces') {
                state.fetchingSpaces = fetchingStatus
            } else if (fetchingObject === 'orgInvitations') {
                state.fetchingOrgInvitations = fetchingStatus
            } else if (fetchingObject === 'orgUsers') {
                state.fetchingOrgUsers = fetchingStatus
            } else if (fetchingObject === 'orgTokens') {
                state.fetchingOrgTokens = fetchingStatus
            }
        },
        setSpacePrimarySnapshots: function (state, snapshotData) {
            const orgSpaces = snapshotData.filter(space => space.lock_mode_name !== 'DELETING')
            const dataSpaces = orgSpaces.filter(space => space.space_type === 0)
            const eduSpaces = orgSpaces.filter(space => space.space_type === 1)
            const resSpaces = orgSpaces.filter(space => space.space_type === 2)
            state.spacesWithPrimarySnapshots = orgSpaces
            state.educationSpaces = sortArray(eduSpaces, 'space_long_id', 'ascending', true)
            state.researchSpaces = sortArray(resSpaces, 'space_long_id', 'ascending', true)
            state.datasetSpaces = sortArray(dataSpaces, 'space_long_id', 'ascending', true)
            state.recentEducationSpaces = sortArray(eduSpaces, 'sid', 'descending', false).slice(0, 3)
            state.recentResearchSpaces = sortArray(resSpaces, 'sid', 'descending', false).slice(0, 3)
            state.recentDatasetSpaces = sortArray(dataSpaces, 'sid', 'descending', false).slice(0, 3)
        }
    },
    actions: {
        fetchOrgSpaces: function (context, oid) {
            context.commit('setFetching', { fetchingObject: 'orgSpaces', fetchingStatus: true })
            return $axios
                .get(`/orgs/${oid}/primary_snapshots`, { max_snapshots: 1 })
                .then(response => {
                    context.commit('setSpacePrimarySnapshots', response.data)
                    const userHasSpaceAdminRole = space => isAdminRole(space.space_role)
                    if (response.data.some(userHasSpaceAdminRole)) {
                        context.dispatch('userStore/fetchUserToApproveRequests', {}, { root: true })
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.code === 'conn_not_registered') {
                        router.push({ name: 'welcome' })
                    }
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'orgSpaces', fetchingStatus: false })
                })
        },
        fetchOrgUsers: function (context, oid) {
            context.commit('setFetching', { fetchingObject: 'orgUsers', fetchingStatus: true })
            $axios
                .get(`/orgs/${oid}/list_users`)
                .then(function (response) {
                    context.commit('setUsers', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'orgUsers', fetchingStatus: false })
                })
        },
        fetchOrgTokens: function (context, oid) {
            context.commit('setFetching', { fetchingObject: 'orgTokens', fetchingStatus: true })
            $axios
                .get(`/orgs/${oid}/org_tokens`)
                .then(function (response) {
                    context.commit('setTokens', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'orgTokens', fetchingStatus: false })
                })
        },
        fetchOrgInvitations: function (context, oid) {
            context.commit('setFetching', { fetchingObject: 'orgInvitations', fetchingStatus: true })
            $axios
                .post(`/orgs/${oid}/invitations`, { children_instance_invitations: false, children_space_invitations: false })
                .then(function (response) {
                    context.commit('setOrgInvitations', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'orgInvitations', fetchingStatus: false })
                })
        }
    },
    getters: {
        orgLongName: (state, getters, rootState) => {
            if (rootState.route.params.oid !== undefined) {
                const org = rootState.userOrgs.find(org => org.oid.toString() === rootState.route.params.oid.toString())
                if (org) {
                    return org.long_id
                }
            }
            return ''
        },
        isOrgManager: (state, getters, rootState) => {
            if (rootState.currentOrgData) {
                const currentOrg = rootState.userOrgs.find(org => org.oid.toString() === rootState.currentOrgData.oid.toString())
                if (currentOrg && isManagerRole(currentOrg.role)) {
                    return true
                }
            }
            return false
        },
        isOrgFaculty: (state, getters, rootState) => {
            if (rootState.currentOrgData) {
                const currentOrg = rootState.userOrgs.find(org => org.oid.toString() === rootState.currentOrgData.oid.toString())
                if (currentOrg && isFacultyRole(currentOrg.role)) {
                    return true
                }
            }
            return false
        },
        orgTablesStatus: (state, getters, rootState) => {
            if (rootState.route.params.oid && rootState.userOrgs) {
                const currentOrg = rootState.userOrgs.find(org => org.oid.toString() === rootState.route.params.oid.toString())
                if (currentOrg) {
                    return currentOrg.tables_enabled
                }
            }
            return null
        }
    }
}

export default orgStore
