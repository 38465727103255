import { DateTime } from 'luxon'

export const displayCookiePopup = interval => {
    // Show popup only once per session
    if (sessionStorage.getItem('nvPopupShown')) return

    const popupShownLastTime = localStorage.getItem('nvLastPopup')
    const shownLast = DateTime.fromISO(popupShownLastTime)
    if (!interval && shownLast.isValid) return false

    const now = DateTime.now()
    const shownNext = DateTime.fromISO(shownLast).plus(interval)
    if (!shownNext.isValid) return true

    const show = now > shownNext
    return show
}
