const tourStore = {
    namespaced: true,
    state: {
        stageTourStarted: false,
        stageAppTourStarted: false,
        stageTableTourStarted: false
    },
    mutations: {
        stageTourStarted: function (state, val) {
            state.stageTourStarted = val
        },
        stageAppTourStarted: function (state, val) {
            state.stageAppTourStarted = val
        },
        stageTableTourStarted: function (state, val) {
            state.stageTableTourStarted = val
        }
    },
    actions: {},
    getters: {}
}

export default tourStore
