import $axios from './backend.js'

export async function delay(ms) {
    return await new Promise(resolve => setTimeout(resolve, ms))
}

export function fetchAppStartupSchedules({ sid, all }) {
    let apiString = `/spaces/${sid}/startup_schedules`
    if (all) {
        apiString += `?all_schedules=true`
    }
    return $axios.get(apiString)
}

export function setAppStartupSchedule({ sid, aoid, startupTime, nodePool, stopAfterMinutes }) {
    const body = { aoid, startup_time: startupTime, node_pool: nodePool, stop_after_minutes: stopAfterMinutes }
    return $axios.post(`/spaces/${sid}/schedule_startup`, body)
}

export function updateAppStartupSchedule({ ssid, startupTime, nodePool, stopAfterMinutes }) {
    const body = { startup_time: startupTime, node_pool: nodePool }
    if (nodePool) {
        body.stop_after_minutes = stopAfterMinutes
    }
    return $axios.patch(`/startup_schedules/${ssid}`, body)
}

export function deleteAppStartupSchedule(ssid) {
    return $axios.delete(`/startup_schedules/${ssid}`)
}

export async function fetchTask(taskId) {
    try {
        let task = await $axios.get(`/tasks/${taskId}`)
        while (!task.data.finished) {
            await delay(2000)
            task = await $axios.get(`/tasks/${taskId}`)
        }
        return task
    } catch (error) {
        return error
    }
}

export function enableVimeoForSpace(sid) {
    return $axios.patch(`/spaces/${sid}`, { vimeo_enabled: true })
}

export function fetchVimeoVideos(sid) {
    return $axios.get(`/spaces/${sid}/videos`)
}
