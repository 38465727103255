import Enum from 'enum'

export const enumsData = {
    data() {
        return {
            spaceTypes: {
                EDUCATION_SPACE: 1,
                RESEARCH_SPACE: 2,
                VENDOR_SPACE: 0,
                KNOWLEDGE_BASE_SPACE: 3
            },
            spaceVisibilityCodes: {
                PUBLIC_SPACE: 0,
                AFFILIATE_ONLY_SPACE: 1,
                FACULTY_ONLY_SPACE: 2,
                PRIVATE_SPACE: 3
            },
            spaceStatus: {
                WAKING: 'WAKING',
                AWAKE: 'AWAKE',
                RESTED: 'RESTED',
                RESTING: 'RESTING',
                PRE_RESTING: 'PRE_RESTING'
            },
            nuvolosObjectTypes: {
                FILE: 'file',
                FOLDER: 'folder',
                TABLE: 'table',
                COLUMN: 'column',
                APPLICATION: 'application',
                APPLICATIONS: 'applications',
                SNAPSHOT: 'snapshot',
                INSTANCE: 'instance',
                SPACE: 'space',
                ORGANIZATION: 'organization'
            },
            userWorkAreas: {
                WORKSPACE: 'workspace',
                PERSONAL: 'personal'
            },
            roleTypes: {
                ORG_MANAGER: 'MANAGER',
                ORG_FACULTY: 'FACULTY',
                ORG_MEMBER: 'MEMBER',
                SPACE_ADMIN: 'SPACE_ADMIN',
                SPACE_MEMBER: 'None',
                INSTANCE_EDITOR: 'EDITOR',
                INSTANCE_OBSERVER: 'OBSERVER',
                INSTANCE_VIEWER: 'VIEWER'
            },
            instanceFixedNames: {
                MASTER_INSTANCE: 'Master',
                DISTRIBUTED_INSTANCE: 'Distributed'
            },
            orgTokenScopes: {
                MANAGER: 0,
                FACULTY: 10,
                MEMBER: 30
            },
            orgTokenTypes: {
                XPRESSFEED: 0
            },
            folderTypesLabels: {
                WORKSPACE_FILES: 'files',
                PERSONAL_FILES: 'home',
                LIBRARY_FILES: 'lib'
            },
            taskStatusTypes: new Enum({
                REJECTED: 0,
                CREATED: 1,
                QUEUED: 2,
                RUNNING: 3,
                COMPLETED: 4,
                FAILED: 5,
                ARCHIVED: 6,
                CONTAINS_ERRORS: 7,
                CANCELLED: 8
            }),
            distributionReasons: {
                MATERIAL_SHARING: 'DISTRIBUTION',
                ASSIGNMENT: 'ASSIGNMENT'
            },
            trialTiers: {
                FREE: 0,
                PAID: 10
            },
            tours: {
                PRESTART_MINIMUM_INSTANCE_COUNT: 20
            },
            newSpace: {
                ROUTES_BY_TYPE: ['home-add-dataset', 'home-add-class', 'home-add-research-project']
            },
            secretLevels: { 0: 'User', 1: 'Application', 2: 'Space', 3: 'Organization' },
            // This is Luxon's Math format or null
            // https://moment.github.io/luxon/#/math?id=time-math
            cookiePopupInterval: { month: 6 }
        }
    }
}
