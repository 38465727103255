import $axios from '@/backend.js'
import { sortArray, isEditorRole, isObserverRole, isDistributedInstance, isMasterInstance, isViewerRole } from '@/utils'
import { getInstance } from '@/auth/auth'

const getDefaultState = () => {
    return {
        instanceSnapshots: [],
        instanceUsers: [],
        canSelectAndDistributeSnapshot: false,
        fetchingSnapshots: false,
        snapshotPollingInterval: null
    }
}
const instanceStore = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetInstanceState(state) {
            if (state.snapshotPollingInterval) {
                clearInterval(state.snapshotPollingInterval)
            }
            Object.assign(state, getDefaultState())
        },
        setSnapshotPollingInterval: function (state, interval) {
            state.snapshotPollingInterval = interval
        },
        setSnapshots: function (state, snapshots) {
            state.instanceSnapshots = snapshots.filter(snapshot => snapshot.lock_mode_name !== 'DELETING')
        },
        setUsers: function (state, users) {
            state.instanceUsers = users
        },
        setFetching: function (state, newVal) {
            state.fetchingSnapshots = newVal
        }
    },
    actions: {
        resetInstanceState: function (context) {
            context.commit('resetInstanceState')
        },
        setSnapshotPollingInterval: function (context, iid) {
            context.commit(
                'setSnapshotPollingInterval',
                setInterval(() => {
                    if (getInstance().isAuthenticated) {
                        context.dispatch('fetchSnapshots', iid)
                    }
                }, 300000)
            )
        },
        fetchSnapshots: function (context, iid) {
            return $axios
                .get(`instances/${iid}/snapshots`)
                .then(function (response) {
                    const sortByIdDesc = sortArray(response.data, 'snid', 'descending', false)
                    context.commit('setSnapshots', sortByIdDesc)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        fetchInstanceSnapshots: function (context, iid) {
            context.commit('setFetching', true)
            return context.dispatch('fetchSnapshots', iid).finally(() => {
                context.commit('setFetching', false)
            })
        },
        fetchInstanceUsers: function (context, iid) {
            $axios.get(`/instances/${iid}/list_users`).then(function (response) {
                context.commit('setUsers', response.data)
            })
        },
        updateCreateAndInviteStep: function (context, newVal) {
            context.commit('updateCreateAndInviteStep', newVal)
        },
        resetCreateAndShareData: function (context) {
            context.commit('resetCreateAndShareData')
        }
    },
    getters: {
        currentInstanceDescription: (state, getters, rootState) => {
            if (rootState.route.params.iid !== undefined && rootState.spaceStore.spaceInstances.length) {
                const instance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (instance) {
                    return instance.description
                }
            }
            return 'No description available for this instance'
        },
        instanceLongNameById: (state, getters, rootState) => iid => {
            if (iid !== undefined && iid !== null && rootState.spaceStore.spaceInstances.length) {
                const instance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === iid.toString())
                if (instance) {
                    return instance.long_id
                }
            }
            return null
        },
        instanceRearchiveAfterTimestamp: (state, getters, rootState) => iid => {
            if (iid !== undefined && iid !== null && rootState.spaceStore.spaceInstances.length) {
                const instance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === iid.toString())
                if (instance) {
                    return instance.rearchive_after_timestamp
                }
            }
            return null
        },
        isInstanceEditor: (state, getters, rootState) => {
            if (rootState.route.params.iid !== undefined && rootState.spaceStore.spaceInstances.length) {
                const currentInstance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (currentInstance && isEditorRole(currentInstance.role)) {
                    return true
                }
            }
            return false
        },
        isInstanceViewer: (state, getters, rootState) => {
            if (rootState.route.params.iid !== undefined && rootState.spaceStore.spaceInstances.length) {
                const currentInstance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (currentInstance && isViewerRole(currentInstance.role)) {
                    return true
                }
            }
            return false
        },
        isInstanceArchived: (state, getters, rootState) => {
            if (rootState.route.params.iid !== undefined && rootState.spaceStore.spaceInstances.length) {
                const currentInstance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (currentInstance.filesystem_prefix) {
                    return false
                } else {
                    return true
                }
            }
            return false
        },
        isInstanceObserver: (state, getters, rootState) => {
            if (rootState.route.params.iid !== undefined && rootState.spaceStore.spaceInstances.length) {
                const currentInstance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (currentInstance && isObserverRole(currentInstance.role)) {
                    return true
                }
            }
            return false
        },
        isDistributedInstance: (state, getters, rootState) => {
            if (rootState.route.params.iid && rootState.spaceStore.spaceInstances.length) {
                const distributedInstance = rootState.spaceStore.spaceInstances.find(
                    instance => instance.iid.toString() === rootState.route.params.iid.toString()
                )
                if (distributedInstance && isDistributedInstance(distributedInstance.long_id)) {
                    return true
                }
            }
            return false
        },
        isMasterInstance: (state, getters, rootState) => {
            if (rootState.route.params.iid && rootState.spaceStore.spaceInstances.length) {
                const masterInstance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (masterInstance && isMasterInstance(masterInstance.long_id)) {
                    return true
                }
            }
            return false
        },
        canDeleteInstanceOrEditFields: (state, getters, rootState) => {
            if (rootState.route.params.iid && rootState.spaceStore.spaceInstances.length) {
                const undeletableInstance = ['distributed', 'master']
                const filteredInstance = rootState.spaceStore.spaceInstances.find(instance => instance.iid.toString() === rootState.route.params.iid.toString())
                if (filteredInstance && undeletableInstance.indexOf(filteredInstance.short_id) > -1) {
                    return false
                }
            }
            return false
        }
    }
}
export default instanceStore
