const TheAdminMain = () => import('../views/TheAdminMain')
const TheAdminOverview = () => import('../views/TheAdminOverview')

export default [
    {
        path: '/',
        name: 'admin-main',
        components: {
            default: TheAdminMain
        },
        children: [
            {
                path: '/admin',
                name: 'admin-overview',
                components: {
                    default: TheAdminOverview
                }
            }
        ]
    }
]
