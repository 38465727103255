import $axios from '@/backend.js'
import { sortArray, isDistributedInstance, isAdminRole, isMasterOrDistributedInstance, randomString, isMasterInstance } from '@/utils'
import { enumsData } from '@/mixins/enums'
import LogRocket from 'logrocket'
import router from '@/router'

const getDefaultState = () => {
    return {
        currentSpace: null,
        spaceInstances: [],
        spaceUsers: [],
        spaceBundles: [],
        fetchingSpaceBundles: false,
        spaceInvitations: [],
        distributedInstanceId: null,
        fetchingInstances: false,
        invitationFromSnapshotView: false,
        fetchingSpaceUsers: null,
        fetchingSpaceInvitations: null,
        mappedResources: null,
        fetchingApplications: false,
        applicationFamilies: [],
        applicationVersions: []
    }
}
const spaceStore = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        setCurrentSpace(state, space) {
            state.currentSpace = space
        },
        resetSpaceState(state) {
            Object.assign(state, getDefaultState())
        },
        setInstances: function (state, instances) {
            state.spaceInstances = instances
        },
        setDistributedInstanceId: function (state, iid) {
            state.distributedInstanceId = iid
        },
        setUsers: function (state, users) {
            state.spaceUsers = users
        },
        setSpaceInvitations: function (state, invitations) {
            state.spaceInvitations = invitations
        },
        setSpaceBundles: function (state, bundles) {
            state.spaceBundles = bundles
        },
        setFetching: function (state, { fetchingObject, fetchingStatus }) {
            if (fetchingObject === 'instances') {
                state.fetchingInstances = fetchingStatus
            } else if (fetchingObject === 'spaceUsers') {
                state.fetchingSpaceUsers = fetchingStatus
            } else if (fetchingObject === 'spaceInvitations') {
                state.fetchingSpaceInvitations = fetchingStatus
            } else if (fetchingObject === 'spaceBundles') {
                state.fetchingSpaceBundles = fetchingStatus
            }
        },
        setMappedResources(state, data) {
            state.mappedResources = data
        },
        setApplicationFamilies: function (state, families) {
            state.applicationFamilies = families
        },
        setApplicationVersions: function (state, versions) {
            state.applicationVersions = versions
        },
        setFetchingApplications(state, val) {
            state.fetchingApplications = val
        }
    },
    actions: {
        resetSpaceState: function (context) {
            context.commit('resetSpaceState')
        },
        setDistributedInstanceId: function (context, instances) {
            if (instances.length) {
                const distributedInstance = instances.find(instance => isDistributedInstance(instance.short_id))
                if (distributedInstance) {
                    context.commit('setDistributedInstanceId', distributedInstance.iid)
                }
            }
        },
        fetchSpaceInstances: function (context, sid) {
            if (context.getters.currentSpaceType === null) return
            context.commit('setFetching', { fetchingObject: 'instances', fetchingStatus: true })
            return $axios
                .get(`spaces/${sid}/instances`)
                .then(({ data }) => {
                    const master = data.filter(instance => isMasterInstance(instance.long_id))
                    const distributed = data.filter(instance => isDistributedInstance(instance.long_id))
                    const nonMasterAndDistributed = data.filter(instance => !isMasterOrDistributedInstance(instance.long_id))
                    const sortedNonMasterAndDistributed = sortArray(nonMasterAndDistributed, 'long_id', 'ascending', true)

                    let finalInstanceList = null
                    if (
                        context.getters.currentSpaceType === enumsData.data().spaceTypes.VENDOR_SPACE ||
                        context.getters.currentSpaceType === enumsData.data().spaceTypes.RESEARCH_SPACE
                    ) {
                        finalInstanceList = master.concat(sortedNonMasterAndDistributed)
                    } else {
                        finalInstanceList = master.concat(distributed).concat(sortedNonMasterAndDistributed)
                    }

                    context.commit('setInstances', finalInstanceList)
                    context.dispatch('setDistributedInstanceId', finalInstanceList)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'instances', fetchingStatus: false })
                })
        },
        fetchSpaceUsers: function (context, sid) {
            context.commit('setFetching', { fetchingObject: 'spaceUsers', fetchingStatus: true })
            $axios
                .get(`/spaces/${sid}/list_all_users`)
                .then(function (response) {
                    context.commit('setUsers', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'spaceUsers', fetchingStatus: false })
                })
        },
        async fetchCurrentSpace(context) {
            try {
                const { data } = await $axios.get(`/spaces/${router.currentRoute.params.sid}`)
                context.commit('setCurrentSpace', data)
                return data
            } catch (error) {
                context.commit(
                    'showSnackBar',
                    {
                        snackBarText: 'Failed to get space data!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    },
                    { root: true }
                )
                return error
            }
        },
        fetchSpaceBundles: function (context, sid) {
            context.commit('setFetching', { fetchingObject: 'spaceBundles', fetchingStatus: true })
            $axios
                .get(`/space/${sid}/bundles?handins=true`)
                .then(function (response) {
                    context.commit('setSpaceBundles', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'spaceBundles', fetchingStatus: false })
                })
        },
        fetchSpaceInvitations: function (context, sid) {
            context.commit('setFetching', { fetchingObject: 'spaceInvitations', fetchingStatus: true })
            $axios
                .post(`/spaces/${sid}/invitations_v2`, { children_instance_invitations: true })
                .then(function (response) {
                    context.commit('setSpaceInvitations', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingObject: 'spaceInvitations', fetchingStatus: false })
                })
        },
        fetchMappedResources(context, sid) {
            $axios.get(`/spaces/${sid}/resource_allocation`).then(response => {
                context.commit('setMappedResources', response.data)
            })
        },
        async fetchApplications(context, sid) {
            context.commit('setFetchingApplications', true)
            try {
                const fetchingFamilies = $axios.get(`/spaces/${sid}/image_families`)
                const fetchingVersions = $axios.get(`/spaces/${sid}/images_v2`)

                const [families, versions] = await Promise.all([fetchingFamilies, fetchingVersions])
                context.commit('setApplicationFamilies', families.data)
                context.commit('setApplicationVersions', versions.data)
            } catch (error) {
                console.log(error)
            } finally {
                context.commit('setFetchingApplications', false)
            }
        }
    },
    getters: {
        spaceLongNameById: (state, getters, rootState) => sid => {
            if (sid !== undefined && sid !== null) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === sid.toString())
                if (space) {
                    return space.space_long_id
                }
            }
            return null
        },
        spaceDescriptionById: (state, getters, rootState) => sid => {
            if (sid !== undefined && sid !== null) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === sid.toString())
                if (space) {
                    return space.space_description
                }
            }
            return 'No description available.'
        },
        spaceArchiveTimestampById: (state, getters, rootState) => sid => {
            if (sid !== undefined && sid !== null) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === sid.toString())
                if (space) {
                    return space.space_archival_timestamp
                }
            }
            return null
        },
        spaceArchivalDateById: (state, getters, rootState) => sid => {
            if (sid !== undefined && sid !== null) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === sid.toString())
                if (space) {
                    return space.space_archive_by_date
                }
            }
            return null
        },
        spaceCreationDateById: (state, getters, rootState) => sid => {
            if (sid !== undefined && sid !== null) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === sid.toString())
                if (space) {
                    return space.space_creation_timestamp
                }
            }
            return null
        },
        spaceArchivalDate: (state, getters, rootState) => {
            return getters.spaceArchivalDateById(rootState.route.params.sid)
        },
        spaceCreationDate: (state, getters, rootState) => {
            return getters.spaceCreationDateById(rootState.route.params.sid)
        },
        isSpaceArchived: (state, getters, rootState) => {
            if (getters.spaceArchiveTimestampById(rootState.route.params.sid)) {
                return true
            }
            return false
        },
        isSpaceAdmin: (state, getters, rootState) => {
            if (rootState.route.params.sid !== undefined && rootState.orgStore.spacesWithPrimarySnapshots) {
                const currentSpace = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === rootState.route.params.sid.toString())
                if (currentSpace && isAdminRole(currentSpace.space_role)) {
                    return true
                }
            }
            return false
        },
        currentSpaceType: (state, getters, rootState) => {
            if (rootState.route.params.sid !== undefined && rootState.orgStore.spacesWithPrimarySnapshots) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === rootState.route.params.sid.toString())
                if (space) {
                    return space.space_type
                }
            }
            return null
        },
        currentSpaceVisibility: (state, getters, rootState) => {
            if (rootState.route.params.sid !== undefined && rootState.orgStore.spacesWithPrimarySnapshots) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === rootState.route.params.sid.toString())
                if (space) {
                    return space.space_visibility_type
                }
            }
            return null
        },
        currentSpaceArchived: (state, getters, rootState) => {
            if (rootState.route.params.sid !== undefined && rootState.orgStore.spacesWithPrimarySnapshots.length) {
                const space = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === rootState.route.params.sid.toString())
                if (space) {
                    return space.space_archival_timestamp
                }
            }
            return null
        },
        currentSpaceData: (state, getters, rootState) => {
            if (rootState.route.params.sid && rootState.orgStore.spacesWithPrimarySnapshots) {
                const spaceData = rootState.orgStore.spacesWithPrimarySnapshots.find(space => space.sid.toString() === rootState.route.params.sid.toString())
                return spaceData
            } else {
                return null
            }
        },
        assignmentsExist(state) {
            const distributionBundles = state.spaceBundles.filter(bundle => bundle.bundle_type_name === enumsData.data().distributionReasons.ASSIGNMENT)
            if (distributionBundles.length) {
                return true
            }
            return false
        },
        privateInstances(state) {
            return state.spaceInstances.filter(instance => !isMasterOrDistributedInstance(instance.long_id))
        },
        isTrialSpace(state, getters) {
            return getters.currentSpaceData?.snapshot_location === 'AZFS_PREMIUM'
        },
        applicationTags(state) {
            const allTags = {}
            try {
                state.applicationVersions.forEach(version => {
                    if (version.tags == null) return
                    for (const [tagType, tagNames] of Object.entries(version.tags)) {
                        const tagNamesArr = Array.isArray(tagNames) ? tagNames : [tagNames]
                        tagNamesArr.forEach(tagName => {
                            if (!allTags[`${tagType}:${tagName}`]) allTags[`${tagType}:${tagName}`] = []
                            allTags[`${tagType}:${tagName}`].push(version.imid)
                        })
                    }
                })
            } catch (err) {
                LogRocket.captureMessage(`app image configuration error [${randomString(8)}]`, {
                    extra: err.message
                })
            }
            return allTags
        },
        isSpaceRested(state, getters, rootState) {
            return state.currentSpace?.resting_state === enumsData.data().spaceStatus.RESTED
        },
        isSpacePreResting(state, getters, rootState) {
            return state.currentSpace?.resting_state === enumsData.data().spaceStatus.PRE_RESTING
        },
        isSpaceResting(state, getters, rootState) {
            return state.currentSpace?.resting_state === enumsData.data().spaceStatus.RESTING
        },
        isSpaceWaking(state, getters, rootState) {
            return state.currentSpace?.resting_state === enumsData.data().spaceStatus.WAKING
        },
        isSpaceInAnyRestState(state, getters, rootState) {
            return getters.isSpaceRested || getters.isSpacePreResting || getters.isSpaceWaking || getters.isSpaceResting
        },
        isSpaceInAnyRestStateExceptPreResting(state, getters, rootState) {
            return getters.isSpaceRested || getters.isSpaceWaking || getters.isSpaceResting
        }
    }
}
export default spaceStore
