import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import IconUnixTraversalUp from '@/components/IconUnixTraversalUp.vue'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {
            dropdown: 'mdi-chevron-down',
            unixTraversalUp: {
                component: IconUnixTraversalUp
            }
        }
    },
    theme: {
        themes: {
            light: {
                primary: '#3381ff',
                secondary: '#606060',
                anchor: '#3381ff',
                accent: '#007891',
                error: '#f44336',
                warning: '#ff5722',
                info: '#1186D9',
                success: '#11CF82'
            },
            dark: {
                primary: '#114ECF',
                secondary: '#19A9C2',
                accent: '#007891',
                error: '#f44336',
                warning: '#ff5722',
                info: '#1186D9',
                success: '#11CF82'
            }
        },
        options: {
            customProperties: true
        }
    }
})
