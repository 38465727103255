import LogRocket from 'logrocket'

export function initLogRocketSession() {
    LogRocket.init('unkwc1/nuvolos', {
        network: {
            requestSanitizer: request => {
                // do not store app password
                if (request.url.toLowerCase().indexOf('start_app') !== -1) {
                    if (request.body) {
                        const body = JSON.parse(request.body)
                        if (body.app_pwd) {
                            body.app_pwd = 'redacted'
                            request.body = JSON.stringify(body)
                        }
                    }
                }
                if (request.url.toLowerCase().indexOf('register_with_invitation') !== -1) {
                    if (request.body) {
                        const body = JSON.parse(request.body)
                        if (body.password) {
                            body.password = 'redacted'
                            request.body = JSON.stringify(body)
                        }
                    }
                }

                return request
            }
        }
    })
}

export function identifySession(to) {
    const id = to.uid
    LogRocket.identify(id)
}
