import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'
import router from './router'
import store from './store'
import './filters'
import AsyncComputed from 'vue-async-computed'
import VueMask from 'v-mask'
import { DateTime, Duration } from 'luxon'
import VueKatex from 'vue-katex'
import { isError, isProduction } from '@/helpers'

import { sync } from 'vuex-router-sync' // done. Returns an unsync callback fn

// Import the plugin here
import { Auth0Plugin } from './auth/auth'
import axiosPlugin from './plugins/backend'

import wb from './registerServiceWorker'
Vue.prototype.$workbox = wb

sync(store, router)

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
    onRedirectCallback: appState => {
        if (appState && appState.targetUrl) {
            router.push({ name: 'login', query: { targetUrl: appState.targetUrl } })
        }
    }
})

const plugins = {
    install() {
        Vue.isError = isError
        Vue.isProduction = isProduction
        Vue.appConfig = window.appConfig
        Vue.prototype.$isError = isError
        Vue.prototype.$isProduction = isProduction
        Vue.prototype.$appConfig = window.appConfig
    }
}
Vue.use(plugins)

// Use async computed
Vue.use(AsyncComputed)

Vue.use(axiosPlugin)

// Use vue ClipBoard
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.use(VueMask)

Vue.use(VueKatex, {
    globalOptions: {
        delimiters: [
            { left: '$$', right: '$$', display: true },
            { left: '\\(', right: '\\)', display: false },
            { left: '\\begin{equation}', right: '\\end{equation}', display: true },
            { left: '\\begin{equation*}', right: '\\end{equation*}', display: true },
            { left: '\\begin{align}', right: '\\end{align}', display: true },
            { left: '\\begin{align*}', right: '\\end{align*}', display: true },
            { left: '\\begin{alignat}', right: '\\end{alignat}', display: true },
            { left: '\\begin{gather}', right: '\\end{gather}', display: true },
            { left: '\\begin{CD}', right: '\\end{CD}', display: true },
            { left: '\\[', right: '\\]', display: true },
            { left: '$\\displaystyle', right: '$', display: true },
            { left: '$', right: '$', display: false }
        ],
        strict: 'warn',
        throwOnError: false,
        preProcess(str) {
            const s = str.replaceAll('\\\n', '\\\\\n')
            return s
        }
    }
})

Vue.config.productionTip = false

Vue.filter('dateTimeToHuman', function (value) {
    if (!value) return
    const dt = DateTime.fromISO(value)
    if (!dt.isValid) {
        return 'Invalid date'
    }
    return dt.toFormat(window.appConfig?.VUE_APP_DATETIMEFORMAT)
})

Vue.filter('dateToHuman', function (value) {
    const dt = DateTime.fromISO(value)
    if (!dt.isValid) {
        return 'Invalid date'
    }
    return dt.toFormat(window.appConfig?.VUE_APP_DAYFORMAT)
})

Vue.filter('durationToHuman', function (seconds) {
    const dur = Duration.fromObject({ seconds })
    if (!dur.isValid) {
        return 'Invalid duration'
    }
    return dur.toFormat(window.appConfig?.VUE_APP_DURATIONFORMAT)
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
