var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{ref:"app",staticStyle:{"background-color":"#fff"},attrs:{"id":"inspire"}},[(
            ![
                'app-open',
                'login',
                'signup-index',
                'signup-home',
                'signup-options',
                'signup-provider',
                'signup-success',
                'invitation-index',
                'invitation-password',
                'invitation-home',
                'invitation-accept',
                'invitation-provider',
                'invitation-authenticated',
                'trial',
                'trial-provider'
            ].includes(_vm.$route.name)
        )?_c('TheToolbar'):_vm._e(),_c('router-view',{attrs:{"name":"permanent-sidebar"}}),_c('v-main',{staticClass:"intercom-padding",style:(_vm.$route.params.aid !== 'undefined' ? 'overflow-y: hidden' : '')},[_c('transition',{attrs:{"name":"fadeonly","mode":"out-in"}},[_c('router-view')],1)],1),_c('v-snackbar',{attrs:{"text":"","value":_vm.$store.state['snackBar'],"timeout":-1,"elevation":"1"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.snackBarIcon === 'check_circle' ? 'success' : _vm.snackBarIcon === 'error' ? 'error' : 'info'}},[_vm._v(" "+_vm._s(_vm.snackBarIcon)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.snackBarText)}}),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold ml-5",attrs:{"text":_vm.snackBarIcon !== 'cookie',"color":"secondary"},on:{"click":function($event){return _vm.$store.dispatch('hideSnackBar')}}},[_vm._v(" "+_vm._s(_vm.snackBarLabel || 'Close')+" ")])],1)]),_c('GlobalDialog'),_c('v-snackbar',{attrs:{"color":"primary","vertical":true,"timeout":_vm.forcePWAUpdate ? 0 : 10000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":_vm.update}},'v-btn',attrs,false),[_vm._v("Update")])]}}]),model:{value:(_vm.showUpdatePwa),callback:function ($$v) {_vm.showUpdatePwa=$$v},expression:"showUpdatePwa"}},[(!_vm.forcePWAUpdate)?_c('span',[_vm._v("A new version of Nuvolos is available. Update?")]):_c('span',[_vm._v("Your Nuvolos version requires updating!")])]),_c('div',{staticClass:"shepherd-backdrop"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }